import { format } from "date-fns";
import { DATE_FORMAT_CLIENT } from "constants/date-time";
import mydoc_img from "assets/images/MyDoc.png";
import lifedoc_img from "assets/images/LifeDoc.png";

import margo_img from "assets/images/MARGO.png";

import qdoc_img from "assets/images/QDoc.png";

import qvadis_img from "assets/images/QVadis.png";

import mydoc5_img from "assets/images/MyDoc5.png";

import iqms_img from "assets/images/iqms.png";

/**
 * Format given date
 * @param date
 * @param dateFormat
 * @returns
 */
const formatDateTime = (date = new Date(), dateFormat = DATE_FORMAT_CLIENT) => {
  return format(date, dateFormat);
};
const formatTimeandDate = (date, isTimeshow) => {
  const twelve = 12;
  const ten = 10;
  const zero = 0;
  let newDate = new Date(date);
  if (!isTimeshow) {
    // return newDate.toLocaleDateString("fr-CH");
    return newDate.toLocaleDateString("sv-SE");
  } else {
    let ampm;
    let hour;
    let second;
    let minute;
    if (newDate.getHours() >= twelve) {
      ampm = "PM";
    } else {
      ampm = "AM";
    }
    if (newDate.getHours() < ten) {
      if (newDate.getHours() == zero) hour = "12";
      else hour = "0" + newDate.getHours();
    } else {
      hour = newDate.getHours();
    }
    if (newDate.getMinutes() < ten) {
      minute = "0" + newDate.getMinutes();
    } else {
      minute = newDate.getMinutes();
    }
    if (newDate.getSeconds() < ten) {
      second = "0" + newDate.getSeconds();
    } else {
      second = newDate.getSeconds();
    }
    if (newDate.getHours() > twelve) {
      hour = newDate.getHours() - twelve;
    } else {
      if (newDate.getHours() == zero) hour = "12";
      else hour = newDate.getHours();
    }
    if (hour < ten) {
      if (hour == zero) hour = "12";
      else hour = "0" + hour;
    }
    let timeDate =
      //newDate.toLocaleDateString("fr-CH") +
      newDate.toLocaleDateString("sv-SE") +
      " " +
      hour +
      ":" +
      minute +
      ":" +
      second +
      " " +
      ampm;
    return timeDate;
  }
};
const getImg = (imgname) => {
  switch (imgname) {
    case "MyDoc":
      return mydoc_img;
    case "LifeDoc":
      return lifedoc_img;
    case "MARGO":
      return margo_img;
    case "QDoc":
      return qdoc_img;
    case "QVadis":
      return qvadis_img;
    case "myDoc5":
      return mydoc5_img;
    case "IQMS":
      return iqms_img;  
    default:
      return mydoc_img;
  }
  // MyDoc
  // LifeDoc
  // MARGO
  // QDoc
  // QVadis
  //IQMS
};
export { formatDateTime, formatTimeandDate, getImg };
