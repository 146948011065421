import React from "react";
import { Row, Container, Col, List } from "reactstrap";
import { EuiTitle, EuiListGroup } from "@elastic/eui";
import "./Aboutims.scss";
// import { Appfooter } from "components/Appfooter";

export const Aboutims = () => {
  return (
    <>
      <Container fluid className="main_continer">
        <Row>
          <Col md="12" xs="12" lg="12" className="font-family-arial aboutims-body">
            <EuiTitle size="m">
              <h1>Welcome!</h1>
            </EuiTitle>
            <div className="div_ele">
              <EuiTitle size="xs">
                <h4>
                This User Interface can be used to find officially approved regulations and processes in their current version, which are hosted in myDoc5, QVadis, iQMS and QDoc3.
                </h4>
              </EuiTitle>
            </div>
            <div className="div_ele">
              <EuiTitle size="xs">
                <h4>search:</h4>
              </EuiTitle>
              <p>
                After you typed your search query in the field and executed the
                search, you can further narrow the results by applying the
                filters on the left. If you like to open one of the shown search
                results, just click on the provided link.
              </p>
              <p>
                You can use "Google"-like search characters too, like the
                &lt;*&gt; as a wildcard in your search, or &lt;NOT xyz&gt; to
                exclude, or &lt;xyz&gt; to include certain criteria. Typing your
                search request within quotation marks ("_") will search exactly
                for this text in the provided order.
              </p>
              <p>
                Please keep in mind that the data used for these filters is
                depending on the metadata provided by our tools
              </p>
              <p>
                To access GxP regulations in QDoc you need to be registered
                user.
              </p>
            </div>
            <div className="div_ele">
              <EuiTitle size="xs">
                <h4>Regulation collections:</h4>
              </EuiTitle>
              <p>
                The links provided below an overview of regulations issued by
                the respective functions.
              </p>
              <p>
                It also leads you to the homepages of the respective
                organizational management systems:
              </p>
              <p><a href="/#/globelef">Global Enabling Functions</a></p>
              <p><a href="/#/countryorganisation">Country Organizations</a></p>
            </div>
            <div className="div_ele">
              <EuiTitle size="xs">
                <h4> Content in IMS:</h4>
              </EuiTitle>

              <p>
                The regulations and processes which are available via the IMS
                User Interface are:
              </p>
              <List className="list_style">
                <li>
                  <a href="https://go.cnb/margo" target={"_blank"}>MARGO: </a>all published and effective Corporate Policies and
                  Directives (centrally managed)
                </li>
                <li><a href="https://go.cnb/mydoc-help" target={"_blank"}>myDoc:</a> all published and effective regulations (local and global)</li>
                <li><a href="https://go.cnb/lifedoc" target={"_blank"}>LifeDoc:</a> all non restricted, effective GxP regulations</li>
                <li><a href="https://go.cnb/qdoc-help" target={"_blank"}>QDoc:</a> all effective GxP regulations</li>
              </List>
              <br></br>
              <p className="p_links">
                To explore the <a href="https://bayernet.int.bayer.com/de-de/service/support-und-solutions/ims" target="_blank">IMS Framework</a>, providing additional insights and details, please click <a href="https://bayernet.int.bayer.com/de-de/service/support-und-solutions/ims" target="_blank">here</a>.
              </p>
            </div>
            <div className="div_ele">
              <EuiTitle size="xs">
                <h4> Contact us:</h4>
              </EuiTitle>
              <p>
                With "Feedback" you can send general or specific feedback
                regarding the IMS User Interface
              </p>
              <p>
                Please don't hesitate to use the feedback functionality to give
                feedback or to ask your questions.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <Appfooter /> */}
    </>
  );
};
