import React, { useState, useEffect } from "react";
import { Row, Container, Col } from "reactstrap";
import ims_image from "assets/images/landingpage/IMS-Image-01.png";
import enable_funtion_img from "assets/images/landingpage/Icon-Global-Enabling-Functions.png";
import feedback_img from "assets/images/landingpage/Icon-Feedback.png";
import margo_img from "assets/images/landingpage/Icon-Margo.png";
import country_organisation_img from "assets/images/landingpage/Icon-Country-Organisation.png";
import "./HomebodyComponent.scss";
import { GetNews } from 'services/news.service';
import { formatTimeandDate, getImg } from "core/utils/commonHelpers";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
  import { routes } from "constants/routes";

export const HomebodyComponent = () => {
  let [NewsList_, setNewsList] = useState([]);
  let [NewsListModal_, setNewsModalList] = useState([]);
  let NewsList = [];
  let NewsListModal = [];

  const [showNews, setShowNews] = useState(false);
  const handleCloseNews = () => setShowNews(false);
  const handleShowNews = () => setShowNews(true);

  useEffect(() => {
    GetNewsResult()
  }, []);

  const OpenMailBox = () => {
    
    window.location.href = "mailto:ims_user_interface_feedback@bayer.com?subject=IMS Feedback&body=" + "";

  };
  const GetNewsResult = async () => {

    const response = await GetNews()
      ?.then((response) => {
        console.log("responsGet News:", response);

        response.data.value.forEach((b, index) => {
          if (index < 3) {
            NewsList.push(b);
          }
          if (index < 100) {
            NewsListModal_.push(b);
          }
          
        })

        setNewsList(NewsList);
        setNewsModalList(NewsListModal_);



      })
      .catch((error) => { console.log(error) });

  }

  const clicked = (val) => {
    console.log("here", val);
  };

  
  const navigate = useNavigate();
  const RedirectToAboutIMS = () => {
      navigate(routes.aboutims.path);  
    };
    
    const contentFormatter=(string) => {
      const regex = /(<([^>]+)>)/gi;
      const newString = string.replace(regex, "");
      const result = newString.replace(/[\u200B-\u200D\uFEFF]/g, '');
      console.log(result,'testt')
      return result
    };
  return (
    <>
    <Row className="col1 home-body-content">
      <Col md="5" className="col_sub1 col-lg">
        <div className="heading1_div">
          <Row>
            <Col xs="10">
              <p className="col1_heading">news and updates</p>
            </Col>
            <Col xs="2" className="col1_a">
              <a onClick={handleShowNews}>See all</a>
            </Col>
          </Row>
        </div>
        <div>
          {NewsList_.map((data) => {            
            return (
              <div className="content_cls" key={data.RowKey}>
                <p className="date_cls">{formatTimeandDate(data.issue_date, false)}</p>
                <p className="heading_cls">
                  {data.title}
                </p>
                <p className="body_cls">
                  {contentFormatter(data.content)}
                </p>
              </div>
            )
          })
          }
          {/* <div className="content_cls">
            <p className="date_cls">November 08 ,2022</p>
            <p className="heading_cls">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout
            </p>
            <p className="body_cls">
              The point of using Lorem Ipsum is that it has a more-or-less
              normal distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English
            </p>
          </div>
          <div className="content_cls">
            <p className="date_cls">November 08 ,2022</p>
            <p className="heading_cls">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout
            </p>
            <p className="body_cls cls2">
              The point of using Lorem Ipsum is that it has a more-or-less
              normal distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English
            </p>
          </div> */}
        </div>
      </Col>
      <Col md="3" className="col_sub3">
        <div className="col_sub3_div">
          <div className="innerDiv" style={{cursor:"pointer"}} onClick={() => { RedirectToAboutIMS() }}>
            <p className="heading2">About IMS</p>
            <img src={ims_image} alt="img image" className="img_cls" />
            <p className="sub_heading">Welcome!</p>
            <p className="body_cls">
            This User Interface can be used to find officially approved regulations and processes in their current version, which are hosted in myDoc5, QVadis, iQMS and QDoc3.
            </p>
          </div>
        </div>
      </Col>
      <Col md="4" className="col_sub4">
        <div>
          <div className="childDivs">
            <Row>
              <Col xs="2">
                <img
                  src={enable_funtion_img}
                  alt="globel enabling function img"
                />
              </Col>
              <Col xs="10" className="childDivp">
                <a href="/#/globelef"
                
                className="p_tag">

                <p className="p_tag">Global Enabling Function</p>
                </a>
              </Col>
            </Row>
          </div>
          <div className="childDivs">
            <Row>
              <Col xs="2">
                <img
                  src={country_organisation_img}
                  alt="country organisation img"
                />
              </Col>

              <Col xs="10" className="childDivp">
                <a href="/#/countryorganisation"
                
                className="p_tag"
                >

                <p className="p_tag">Country Organisation</p>
                </a>
              </Col>
            </Row>
          </div>

          {/* <div className="childDivs">
            <Row>
              <Col xs="2">
                <img src={margo_img} alt="margo img" />
              </Col>
              <Col xs="10" className="childDivp">
                
                <a target="_blank"
                  href="http://margo.intranet.cnb/Default.aspx?lgO=en&lgD=en&regSt=valid&type=bag_regulation&view=ViewHome&arg=htmlHome"
                  className="p_tag"
                >
                  <p className="p_tag">MARGO</p>
                </a>
              </Col>
            </Row>
          </div> */}
          <div className="childDivs">
            <Row>
              <Col xs="2">
                <img src={feedback_img} alt="feedback img" />
              </Col>
              <Col xs="10" className="childDivp" style={{marginTop:"6px"}}>
                <a onClick={() => { OpenMailBox() }} style={{cursor:"pointer"}} className="p_tag" >Feedback</a>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
    <div className="">
      <Modal show={showNews} onHide={handleCloseNews} className="col1 newsModal">
        <Modal.Header closeButton>

          <Modal.Title>News and Update</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="col-xs-12">

          <div className="newsBodyModal">
          {NewsListModal_.map((data) => {            
            return (
              <div className="content_cls" key={data.RowKey}>
                <p className="date_cls">{formatTimeandDate(data.issue_date, false)}</p>
                <p className="heading_cls">
                  {data.title}
                </p>
                <p className="body_cls">
                  {contentFormatter(data.content)}
                </p>
              </div>
            )
          })
          }
          
        </div>

          </div>
        </Modal.Body>

      </Modal>
      </div>
</>
  );
};
