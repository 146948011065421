import React from "react";
import { Row, Container, Col } from "reactstrap";
import { EuiTitle } from "@elastic/eui";
import "./Imprint.scss";
import { Appfooter } from 'components/Appfooter';

export const Imprint = () => {
  const OpenMailBox = () => {
    
    window.location.href = "mailto:ims_user_interface_feedback@bayer.com?subject=IMS Feedback&body=" + "";

  };
  return (
    <>
    <Container fluid className="main_continer font-family-arial hrefColor">
      <Row>
        <Col md="12" xs="12" lg="12">
          <EuiTitle size="m">
            <h1>Contact</h1>
          </EuiTitle>
          <div className="main_div_box">
            <p>
            This User Interface can be used to find officially approved regulations and processes in their current version, which are hosted in myDoc5, QVadis, iQMS and QDoc3.
            </p>
          </div>
          <div className="main_div_box">
            <p>
              The content comes from a wide variety of areas of the company. The
              contact person for each topic is named directly below:
            </p>
          </div>
          <div className="main_div_box">
          <p>
            For general IMS related questions please contact
            <a onClick={() => { OpenMailBox() }} style={{cursor:"pointer",color: "aqua"}}> IMS_User_Interface_Feedback</a>
          </p>

          <p>
            For all technical issues, please open an issue in{" "}
            <a href="https://bayersi.service-now.com/sp" target="_blank">
              IT4You
            </a>
          </p>
          </div>
        </Col>
      </Row>
    </Container>
    <div style={{position:"fixed"}} className="footer_continer container-fluid">
<Appfooter />
</div>
</>
  );
};
